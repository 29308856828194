const vars = {
    profile: "Profile",
    info: "Info",
    links: "Links",
    skills: "Skills",
    languages: "Languages",
    interests: "Interests",
    employmentHistory: "Employment History",
	projects: "Projects",
    education: "Education",
    courses: "Courses"
};
export default vars;